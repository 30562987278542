import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/logo.svg";
import minilogo from "../../assets/42.svg";
import hamburger from "../../assets/hamburger.svg";

const Navbar: React.FC = () => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    
  };
  return (
    <>
      <section className="bg-[#121618] h-[40px] text-white Oswald Navbar small-navbar lg:flex hidden">
        <div className="container flex items-center justify-end w-full relative px-[1rem]">
          <img src={minilogo} alt="logo" className=" miniLogo w-[65px] h-[65px]" />
          <img src={hamburger} alt="hamburger" onClick={toggleMenu} className="z-[10000]" />
          <div className={`menu flex-col gap-10 text-[16px] font-normal leading-[23px] text-[#F5F5F5] absolute right-0 left-0 top-[-12px] bg-black h-[100vh] z-[888] items-center justify-center ${isMenuOpen ? 'flex' : 'hidden'}`}>
          <Link to="/" onClick={toggleMenu} className={location.pathname === "/" ? "active" : ""}>
            HOME
          </Link>
          <Link to="/menu" onClick={toggleMenu} className={location.pathname === "/menu" ? "active" : ""}>
            MENU
          </Link>
          <Link to="https://tables.toasttab.com/restaurants/1bb20bb6-d5a7-4a44-8961-d0ddb520c263/findTime"  target='_blank' onClick={toggleMenu} className={location.pathname === "/reservation" ? "active" : ""}>
            MAKE A RESERVATION
          </Link>
          <Link to="/gallery" onClick={toggleMenu} className={location.pathname === "/gallery" ? "active" : ""}>
            GALLERY
          </Link>
          <Link to="/contact" onClick={toggleMenu} className={location.pathname === "/contact" ? "active" : ""}>
            CONTACT US
          </Link>
        </div>
        </div>

      </section>
      <section className="bg-[#121618] h-[100px] text-white Oswald Navbar lg-navbar lg:hidden">
        <div className="container flex items-center justify-end w-full relative">
          <img src={logo} alt="logo" className="absolute left-0 top-0 z-10" />

          <div className="flex gap-10 text-[16px] font-normal leading-[23px] text-[#F5F5F5] absolute right-0 top-[10px] md:hidden">
            <Link to="/" className={location.pathname === "/" ? "active" : ""}>
              HOME
            </Link>
            <Link
              to="/menu"
              className={location.pathname === "/menu" ? "active" : ""}
            >
              MENU
            </Link>
            <Link
              to="https://tables.toasttab.com/restaurants/1bb20bb6-d5a7-4a44-8961-d0ddb520c263/findTime"
              className={location.pathname === "/reservation" ? "active" : ""}
              target='_blank'
            >
              MAKE A RESERVATION
            </Link>
            <Link
              to="/gallery"
              className={location.pathname === "/gallery" ? "active" : ""}
            >
              GALLERY
            </Link>
            <Link
              to="/contact"
              className={location.pathname === "/contact" ? "active" : ""}
            >
              CONTACT US
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Navbar;
