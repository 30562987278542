import React, { useState, useContext, useEffect, ReactNode } from "react";
import {
  BannerResponseTypes,
  BannerTypes,
  FoodCategory,
  GalleryItem,
  MenuItem,
  OpeartingHoursResponseTypes,
  OpeartingHoursTypes,
  PageContext,
  PageData,
} from "./type";
import { BASE_URL } from "../utils/config";

export const usePageContext = () => useContext(PageContext);

export const PageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [sandwiches, setSandwiches] = useState<MenuItem[]>([]);
  const [wings, setWings] = useState<MenuItem[]>([]);
  const [insta, setInsta] = useState<OpeartingHoursTypes | undefined>(
    undefined
  );
  const [faceBook, setFaceBook] = useState<OpeartingHoursTypes | undefined>(
    undefined
  );
  const [twitter, setTwitter] = useState<OpeartingHoursTypes | undefined>(
    undefined
  );
  const [youtube, setYoutube] = useState<OpeartingHoursTypes | undefined>(
    undefined
  );
  const [phone, setPhone] = useState<OpeartingHoursTypes | undefined>(
    undefined
  );
  const [address, setAddress] = useState<OpeartingHoursTypes | undefined>(
    undefined
  );
  const [email, setEmail] = useState<OpeartingHoursTypes | undefined>(
    undefined
  );
  const [smokedWings, setSmokedWings] = useState<MenuItem[]>([]);
  const [availableSauces, setAvailableSauces] = useState<MenuItem[]>([]);
  const [grilledCheese, setGrilledCheese] = useState<MenuItem[]>([]);
  const [entreesData, setEntrees] = useState<MenuItem[]>([]);
  const [brunchentreesData, setBrunchentreesData] = useState<MenuItem[]>([]);
  const [appetizers, setAppetizers] = useState<MenuItem[]>([]);
  const [salad, setSalad] = useState<MenuItem[]>([]);
  const [drinks, setDrinks] = useState<MenuItem[]>([]);
  const [brunchCocktails, setBrunchCocktails] = useState<MenuItem[]>([]);
  const [cocktails, setCocktails] = useState<MenuItem[]>([]);
  const [hookahFlavors, setHookahFlavors] = useState<MenuItem[]>([]);
  const [grits, setGrits] = useState<MenuItem[]>([]);
  const [opeartingHours, setOpeartingHours] = useState<OpeartingHoursTypes[]>(
    []
  );
  const [otherFoodCategories, setOtherFoodCategories] = useState<
    FoodCategory[]
  >([]);
  const [otherDrinksCategories, setOtherDrinksCategories] = useState<
    FoodCategory[]
  >([]);
  const [otherBrunchCategories, setOtherBrunchCategories] = useState<
    FoodCategory[]
  >([]);
  const [webPageData, setWebPageData] = useState<OpeartingHoursTypes[]>([]);
  const [loading, setLoading] = useState(true);
  const [bannerData, setBannerData] = useState<BannerTypes[]>([]);
  const [gallery, setGallery] = useState<GalleryItem[] | undefined>(undefined);
  const queryParams = new URLSearchParams({
    field: "createdAt",
    sortOrder: "asc",
    page: "1",
    pageSize: "10000",
  });
  const fetchWebPage = async (): Promise<void> => {
    try {
      const response = await fetch(
        `${BASE_URL}/webpage/list/public?${queryParams}`
      );
      const responseData: OpeartingHoursResponseTypes = await response.json();

      const { data } = responseData;
      setWebPageData(data);

      // Inside fetchWebPage function
      const instaData = data?.find((e) => e.key === "instagram");
      setInsta(instaData);

      const facebookData = data?.find((e) => e.key === "facebook");
      setFaceBook(facebookData);

      const twitterData = data?.find((e) => e.key === "twitter");
      setTwitter(twitterData);

      const youtubeData = data?.find((e) => e.key === "youtube");
      setYoutube(youtubeData);

      const phoneData = data?.find((e) => e.key === "phone_number_");
      setPhone(phoneData);

      const addressData = data?.find((e) => e.key === "address");
      setAddress(addressData);

      const emailData = data?.find((e) => e.key === "email");
      setEmail(emailData);
    } catch (error) {
      console.error("Error fetching page data:", error);
    }
  };

  const fetchOpeartingHours = async (): Promise<void> => {
    try {
      const response = await fetch(
        `${BASE_URL}/opening-hours/list/public?${queryParams}`
      );
      const responseData: OpeartingHoursResponseTypes = await response.json();
      const { data } = responseData;
      setOpeartingHours(data);
    } catch (error) {
      console.error("Error fetching page data:", error);
    }
  };
  const fetchGalleryData = async (): Promise<void> => {
    try {
      const response = await fetch(
        `${BASE_URL}/gallery/list/public?${queryParams}`
      );
      const responseData = await response.json();
      console.log(responseData.data[0].galleryItems, "oooo");

      setGallery(responseData.data[0].galleryItems);
    } catch (error) {
      console.error("Error fetching gallery data:", error);
    }
  };
  const fetchPageData = async (): Promise<void> => {
    try {
      const response = await fetch(
        `${BASE_URL}/menu-category/list/public?${queryParams}`
      );

      const responseData: PageData = await response.json();

      const { data } = responseData;

      const foodCategoriesMap: {
        [key: string]: React.Dispatch<React.SetStateAction<MenuItem[]>>;
      } = {
        APPETIZERS: setAppetizers,
        SALAD: setSalad,
        SANDWICHES: setSandwiches,
        WINGS: setWings,
        "SMOKED WINGS": setSmokedWings,
        "AVAILABLE SAUCES": setAvailableSauces,
        "GRILLED CHEESE": setGrilledCheese,
        ENTREES: setEntrees,
      };

      const drinksCategoriesMap: {
        [key: string]: React.Dispatch<React.SetStateAction<MenuItem[]>>;
      } = {
        "HOOKAH FLAVORS": setHookahFlavors,
        "BRUNCH COCKTAILS": setBrunchCocktails,
        DRINKS: setDrinks,
      };
      const brunchCategoriesMap: {
        [key: string]: React.Dispatch<React.SetStateAction<MenuItem[]>>;
      } = {
        GRITS: setGrits,
        COCKTAILS: setCocktails,
        "BRUNCH COCKTAILS": setBrunchCocktails,
        ENTREES: setBrunchentreesData,
      };
      const processFoodCategories = (
        filteredData: any[],
        categoriesMap: any
      ) => {
        if (filteredData.length > 0) {
          const foodCategories = filteredData[0].foodCategories;
          foodCategories.forEach((foodCategory: any) => {
            const { name, menuItem } = foodCategory;
            const setter = categoriesMap[name];
            if (setter) {
              setter(menuItem);
            } else {
              console.warn(`Unhandled food category: ${name}`);
            }
          });
        }
      };

      processFoodCategories(
        data.filter((e) => e.name === "food"),
        foodCategoriesMap
      );
      processFoodCategories(
        data.filter((e) => e.name === "drinks"),
        drinksCategoriesMap
      );
      processFoodCategories(
        data.filter((e) => e.name === "brunch"),
        brunchCategoriesMap
      );

      const otherFoodCategories = data
        .filter((e) => e.name === "food")[0]
        ?.foodCategories.filter((e: any) => !(e.name in foodCategoriesMap));
      setOtherFoodCategories(otherFoodCategories);

      const otherDrinksCategories = data
        .filter((e) => e.name === "drinks")[0]
        ?.foodCategories.filter((e: any) => !(e.name in drinksCategoriesMap));
      setOtherDrinksCategories(otherDrinksCategories);
      const otherBrunchCategories = data
        .filter((e) => e.name === "brunch")[0]
        ?.foodCategories.filter((e: any) => !(e.name in brunchCategoriesMap));
      setOtherBrunchCategories(otherBrunchCategories);
    } catch (error) {
      console.error("Error fetching page data:", error);
    }
  };

  const fetchBanner = async (): Promise<void> => {
    try {
      const response = await fetch(
        `${BASE_URL}/banner/list/public?${queryParams}`
      );
      const responseData: BannerResponseTypes = await response.json();

      const { data } = responseData;
      setBannerData(data);
    } catch (error) {
      console.error("Error fetching page data:", error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          fetchOpeartingHours(),
          fetchWebPage(),
          fetchPageData(),
          fetchBanner(),
          fetchGalleryData(),
        ]);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching page data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <PageContext.Provider
      value={{
        sandwiches,
        wings,
        insta,
        faceBook,
        twitter,
        youtube,
        phone,
        address,
        email,
        smokedWings,
        availableSauces,
        grilledCheese,
        entreesData,
        appetizers,
        salad,
        drinks,
        cocktails,
        brunchentreesData,
        otherDrinksCategories,
        otherFoodCategories,
        otherBrunchCategories,
        loading,
        brunchCocktails,
        hookahFlavors,
        grits,
        opeartingHours,
        webPageData,
        fetchPageData,
        bannerData,
        gallery
      }}
    >
      {children}
    </PageContext.Provider>
  );
};
