import { createContext } from "react";

export interface MenuItem {
    id: string;
    createdAt: string;
    updatedAt: string;
    isEditable: boolean;
    isDeletable: boolean;
    isDeleted: boolean;
    isActive: boolean;
    name: string;
    price: number;
    description?: string;
    foodCategoryId: string;
  }
  
  export interface FoodCategory {
    id: string;
    createdAt: string;
    updatedAt: string;
    isEditable: boolean;
    isDeletable: boolean;
    isDeleted: boolean;
    isActive: boolean;
    name: string;
    menuCategoryId: string;
    menuItem: MenuItem[];
  }
  
  export interface MenuCategory {
    id: string;
    createdAt: string;
    updatedAt: string;
    isEditable: boolean;
    isDeletable: boolean;
    isDeleted: boolean;
    isActive: boolean;
    name: string;
    foodCategories: FoodCategory[];
  }
  export type commonData ={
    count: number;
    page: number;
    limit: number;
    message: string;
  }
  export type PageData  = commonData& {
    filter(arg0: (category: any) => boolean): unknown;
    data: MenuCategory[];
  }
  
export type OpeartingHoursResponseTypes = commonData& {
  data:OpeartingHoursTypes[]
}
  export interface OpeartingHoursTypes {
  id:          string;
  createdAt:   Date;
  updatedAt:   Date;
  isEditable:  boolean;
  isDeletable: boolean;
  isDeleted:   boolean;
  isActive:    boolean;
  key:         string;
  label:       string;
  value:       string;
}
export type BannerResponseTypes = commonData& {
  data:BannerTypes[]
}
export interface BannerTypes {
  id:           string;
  createdAt:    Date;
  updatedAt:    Date;
  isEditable:   boolean;
  isDeletable:  boolean;
  isDeleted:    boolean;
  isActive:     boolean;
  firstHeader:  string;
  secondHeader: string;
  thirdHeader:  string;
  image:        string;
}

interface PageContextType {
  sandwiches: MenuItem[];
  wings: MenuItem[];
  insta: OpeartingHoursTypes | undefined;
  faceBook: OpeartingHoursTypes | undefined;
  twitter: OpeartingHoursTypes | undefined;
  youtube: OpeartingHoursTypes | undefined;
  phone: OpeartingHoursTypes | undefined;
  address: OpeartingHoursTypes | undefined;
  email: OpeartingHoursTypes | undefined;
  smokedWings: MenuItem[];
  availableSauces: MenuItem[];
  grilledCheese: MenuItem[];
  entreesData: MenuItem[];
  appetizers: MenuItem[];
  salad: MenuItem[];
  drinks: MenuItem[];
  cocktails: MenuItem[];
  brunchCocktails: MenuItem[];
  brunchentreesData: MenuItem[];
  otherDrinksCategories: FoodCategory[];
  otherFoodCategories: FoodCategory[];
  otherBrunchCategories: FoodCategory[];
  hookahFlavors: MenuItem[];
  grits: MenuItem[];
  loading:boolean,
  opeartingHours: OpeartingHoursTypes[];
  webPageData: OpeartingHoursTypes[];
  fetchPageData: () => Promise<void>;
  bannerData:BannerTypes[];
  gallery:GalleryItem[] | undefined;
}

export const PageContext = createContext<PageContextType>({
  bannerData:[],
  sandwiches: [],
  wings: [],
  insta: undefined,
  loading:false,
  brunchentreesData:[],
  otherDrinksCategories:[],
  otherFoodCategories:[],
  otherBrunchCategories:[],
  faceBook: undefined,
  twitter: undefined,
  youtube: undefined,
  phone: undefined,
  address: undefined,
  email: undefined,
  smokedWings: [],
  availableSauces: [],
  grilledCheese: [],
  entreesData: [],
  appetizers: [],
  salad: [],
  drinks: [],
  cocktails: [],
  brunchCocktails: [],
  hookahFlavors: [],
  grits: [],
  opeartingHours: [],
  webPageData: [],
  fetchPageData: async () => {},
  gallery:[]
});


export interface GalleryItems {
  id:           string;
  createdAt:    Date;
  updatedAt:    Date;
  isEditable:   boolean;
  isDeletable:  boolean;
  isDeleted:    boolean;
  isActive:     boolean;
  galleryName:  string;
  galleryItems: GalleryItem[];
}

export interface GalleryItem {
  id:          string;
  createdAt:   Date;
  updatedAt:   Date;
  isEditable:  boolean;
  isDeletable: boolean;
  isDeleted:   boolean;
  isActive:    boolean;
  title:       string;
  description: string;
  category:    Category[];
  type:        string;
  order:       number;
  media:       string;
  galleryId:   string;
}

export interface Category {
  id:          string;
  createdAt:   Date;
  updatedAt:   Date;
  isEditable:  boolean;
  isDeletable: boolean;
  isDeleted:   boolean;
  isActive:    boolean;
  value:       string;
  label:       string;
  key:         string;
}
