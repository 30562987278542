import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import { Footer } from "./components/Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { usePageContext } from "./context/usePageContext";
import { Loader } from "./components/Loader/indesx";

const Home = lazy(() => import("./pages/Home"));
const Menu = lazy(() => import("./pages/Menu"));
const GalleryPage = lazy(() => import("./pages/Gallery"));
const Contact = lazy(() => import("./pages/Contact"));

const App: React.FC = () => {
  const { loading } = usePageContext();

  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <Loader/>
        }
      >
        {loading ? (
          <Loader/>
        ) : (
          <>
            <Navbar />
            <ToastContainer />
            <Routes>
              <Route index element={<Home />} />
              <Route path="menu" element={<Menu />} />
              <Route path="gallery" element={<GalleryPage />} />
              <Route path="contact" element={<Contact />} />
            </Routes>
            <Footer />
          </>
        )}
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
