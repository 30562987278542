import tele from "../../assets/tele.svg";
import email1 from "../../assets/email.svg";
import location from "../../assets/location.svg";
import logo from "../../assets/42.svg";
import facebook from "../../assets/footerLogos/facebook.svg";
import twitter1 from "../../assets/footerLogos/twitter.svg";
import instagram from "../../assets/footerLogos/instagram.svg";
import youtube2 from "../../assets/footerLogos/youtube.svg";
import RouterLink from "../Link";
import { usePageContext } from "../../context/usePageContext";

export const Footer = () => {
  const currentYear = new Date().getFullYear();
  const { insta, faceBook, twitter, youtube, phone, address, email } = usePageContext(); 


  return (
    <div className="flex flex-col footer">
      <section className="bg-black flex py-[5rem] lg:px-[1rem]">
        <div className="container flex items-center justify-between gap-[1rem] w-full text-center md:flex-col mt-[2rem]">
          <div className=" flex-col border-white border rounded-[15px] items-center justify-center h-[143px]   gap-2  w-full relative md:flex hidden">
            <img src={logo} alt="logo" className="absolute top-[-3rem]" />
            <div className="flex gap-3 mt-10">
              <p className="Oswald text-[35px] font-medium flex gap-1">
                <span className="text-[#0796EF]">BAR</span>{" "}
                <span className="text-white">AND</span>{" "}
                <span className="text-[#857878] ">GRILL</span>
              </p>
            </div>
            <div className="flex gap-3 items-center justify-center footerLogo ">
            <RouterLink to={faceBook?.value}>
                  <img src={facebook} alt="facebook" />
              </RouterLink>
              <RouterLink to={twitter?.value}>
                  <img src={twitter1} alt="twitter" />
              </RouterLink>
              <RouterLink to={youtube?.value}>
                  <img src={youtube2} alt="youtube" />
              </RouterLink>
              <RouterLink to={insta?.value}>
                <img src={instagram} alt="instagram" />
              </RouterLink>
            </div>
            <div className="flex gap-5"></div>
          </div>
          <div className="flex flex-col border-white border rounded-[15px] items-center justify-center h-[143px] gap-2 w-full">
            <p className="Oswald text-[16px] font-medium text-[#0796EF] uppercase">
              Connect with Us
            </p>
            <RouterLink to={`tel:${phone?.value}`}>
              <div className="flex gap-3 mt-3">
                <img src={tele} alt="phone" />

                <p className="Oswald text-[16px] font-normal text-[#857878] leading-[16px]">
                  {phone?.value}
                </p>
              </div>
            </RouterLink>
            <RouterLink to={`mailto:${email?.value}`}>
              <div className="flex gap-3 ">
                <img src={email1} alt="email" />

                <p className="Oswald text-[16px] font-normal text-[#857878] leading-[16px]">
                  {email?.value}
                </p>
              </div>
            </RouterLink>
          </div>
          <div className="flex flex-col border-white border rounded-[15px] items-center justify-center h-[143px]   gap-2  w-full relative md:hidden uppercase">
            <img src={logo} alt="logo" className="absolute top-[-3rem]" />
            <div className="flex gap-3 mt-10">
              <p className="Oswald text-[35px] font-medium flex gap-1">
                <span className="text-[#0796EF]">BAR</span>{" "}
                <span className="text-white">AND</span>{" "}
                <span className="text-[#857878] ">GRILL</span>
              </p>
            </div>
            <div className="flex gap-3 items-center justify-center footerLogo ">
              <RouterLink to={faceBook?.value}>
                  <img src={facebook} alt="facebook" />
              </RouterLink>
              <RouterLink to={twitter?.value}>
                  <img src={twitter1} alt="twitter" />
              </RouterLink>
              <RouterLink to={youtube?.value}>
                  <img src={youtube2} alt="youtube" />
              </RouterLink>
              <RouterLink to={insta?.value}>
                <img src={instagram} alt="instagram" />
              </RouterLink>
            </div>
            <div className="flex gap-5"></div>
          </div>
          <div className="flex flex-col border-white border rounded-[15px] items-center justify-center  h-[143px] gap-2  w-full lg:px-2">
            <p className="Oswald text-[16px] font-medium text-[#0796EF] uppercase">
              FIND US
            </p>
            <RouterLink to={address?.value}>
            <div className="flex gap-3 max-[400px]:gap-1 lg:items-start lg:gap-1">
              <img src={location} alt="tele" />
              <p className="Oswald text-[16px] font-normal text-[#857878]">
               {address?.label}
              </p>
            </div>
            </RouterLink>
            
          </div>
        </div>
      </section>
      <section className="bg-[#161616]  md:h-auto p-[1rem] text-center">
        <div className="flex max-[1200px] justify-between font-semibold items-center w-full container md:flex-col text-[14px] md:text-[10px]">
        <p className="Lato text-[#857878]">
      © {currentYear} 42 Bar & Grill. Developed by{" "}
      <a href="https://www.deepnetsoft.com" className="text-[#857878] Lato cursor-pointer" target="_blank">
        Deepnetsoft Solutions
      </a>.
      
    </p>
          <div className="flex justify-between gap-4">
            <h1 className="text-[#857878] Lato cursor-pointer">
              {" "}
              Terms & Conditions
            </h1>
            <h1 className="text-[#857878] Lato cursor-pointer">
              {" "}
              Privacy Policy
            </h1>
          </div>
        </div>
      </section>
    </div>
  );
};
