import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

interface LinkedProps {
  to: string | undefined;
  children: ReactNode;
  className?:string
}

const RouterLink: React.FC<LinkedProps> = ({ to, children ,className}) => {
  return to ? <Link to={to} target="_blank" className={className}>{children}</Link> : null;
};

export default RouterLink;
